import './App.css';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import {Container } from '@material-ui/core';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


import HostLoginView from "./views/hostLoginView";
import EventListView from './views/eventListView';
import HostLiveView from './views/hostLiveView';

const theme = createTheme({
  palette: {
    primary: { main: "#1d1e21" },
    secondary: { main: "#f9a521" },
    error: {main: "#f75252"},
    warning: {main: "#f75252"},
    info: {main: "#717171", light: "#ababab", dark: "1d1e21"}

  },
});


function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
    <Container maxWidth={false} className="App login">
        <Switch>
          <Route exact path="/"><HostLoginView /></Route>
          <Route exact path="/events"><EventListView /></Route>
          <Route exact path="/live/:eventid"><HostLiveView /></Route>

        </Switch>
    </Container>
    </ThemeProvider>
    </Router>
  );
}

export default App;
