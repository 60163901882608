import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import logo from '../assets/exchvnge-logo-white.png';
import Button from '@material-ui/core/Button'
import ExitIcon from '@material-ui/icons/ExitToAppOutlined'

import { useHistory} from 'react-router-dom';
import firebase from '../components/firebase';
import { getAuth, signOut } from 'firebase/auth';


export const adminUsers = ["76d4400d2720ac05ae66120a456c20b5", "457e278091380ab55488c20a03b50590", "7b678c16a018b3fa8bd3a71034edc9ba", "15f5bf3fcf1f2c3e9004e30430a596fb"];

const auth = getAuth();


const AppHeader = props => {
    const history = useHistory();

    const logout = async () => {
        await signOut(auth);
        history.push("/");
    }

    return (<AppBar className="app-header" position="fixed" color="primary">
        <div className="header-content">
            <div className="app-header-logo">
                <img src={logo} className="app-logo" alt="logo" />
            </div>
            {sessionStorage.getItem("uid") !== "" &&
            <div className="app-header-logout">
                        <Button variant="outlined" onClick={ logout }><ExitIcon style={{color:"#ccc"}} /></Button>
            </div>
            }
        </div>
    </AppBar>)
}

export default AppHeader;