import React, {useState, useEffect} from 'react';
import firebase from '../components/firebase';
import { Container, IconButton, Grid, Avatar, Box, TextField, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { getFirestore, collection, doc, getDoc, setDoc, getDocs,addDoc, query, where, orderBy, limit, onSnapshot } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useHistory, useParams, useQuery } from 'react-router-dom';
import AgoraRTC from 'agora-rtc-sdk-ng';
import agoraConfig from '../components/agora';

import AppHeader, {adminUsers} from "../components/appHeader";

import CancelIcon from '@material-ui/icons/Cancel';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import SpeakerNotesOffRoundedIcon from '@material-ui/icons/SpeakerNotesOffRounded';
import SpeakerNotesRoundedIcon from '@material-ui/icons/SpeakerNotesRounded';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import PushPinIcon from '@mui/icons-material/PushPin';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import DeleteForeverIcon  from '@mui/icons-material/DeleteForeverRounded';

const agora = {
    client: null,
    videoTrack: null,
    audioTrack: null,
    cameras: [],
    appid: "9d46e7a108ce4f638d739b6644e65737",
    channel: "",
}

const HostLiveView = (props) => {
    //const [eventid, setEventid] = useState("");
    const [chatVisible, setChatVisible] = useState(false);
    const [chats, setChats] = useState([]);
    const [muted, setMuted] = useState(false);
    const [cameraid, setCameraid] = useState("");
    const history = useHistory();
    const db = getFirestore();
    const [hostCount, setHostCount] = useState(0);
    const [remoteUsers, setRemoteUsers] = useState([]);
    const [chatMessage, setChatMessage] = useState("");
    let { eventid } = useParams();
    //console.log(eventid)
    
   

    useEffect( ()=> {
         const q = query(collection(db, "event-chat"), where("eventid", "==", eventid), orderBy("timestamp", "desc"), limit(50) );

            const chatSnapshot = onSnapshot(q, (chats) => {
                console.log(chats.docs);
                const loadedChats = [];
                chats.docs.forEach( (chat) => {
                    const chatObj = chat.data();
                    chatObj.id = chat.id;
                    if(chatObj.deleted !== true) {
                        loadedChats.push(chatObj);
                    }
                });

                setChats(loadedChats.reverse());
                //console.log(loadedChats);
                var ele = document.getElementById("chat-log");
                if (ele !== null ){
                  
                 ele.scrollTop = ele.scrollHeight;
                }
            });
        
    }, [db, eventid]);

    useEffect( () => { 
        if(sessionStorage.getItem("uid") === "") {
            history.push("/");
        }
       
        
    }, [history]);

    useEffect( () => initAgora(), []);

    useEffect( () => () => leaveChannel(), []);
    
    const initAgora = async () => {
        try {
        agora.channel = eventid;
        agora.client = AgoraRTC.createClient({mode:"live", codec:"vp8", role: "host"});
        const uid = await agora.client.join(agora.appid, agora.channel, null, null);
        console.log(uid + " joined the channel");
        agora.videoTrack = await AgoraRTC.createCameraVideoTrack({encoderConfig: "720p_6"});
        agora.audioTrack = await AgoraRTC.createMicrophoneAudioTrack({});
        await agora.client.enableDualStream(true);
        await agora.client.publish([agora.videoTrack, agora.audioTrack]);
        agora.cameras = await AgoraRTC.getCameras();
        console.log("there are " + agora.client.remoteUsers.length + " users in the channel");
            setRemoteUsers(Array.from(agora.client.remoteUsers));
            setHostCount(agora.client.remoteUsers.length);

        setCameraid(agora.cameras[0]);
        agora.videoTrack.play("livestream-view", {mirror: true})
        agora.client.on("user-published", async (user, mediaType) => {
            console.log("there are " + agora.client.remoteUsers.length + " users in the channel");
            setRemoteUsers(Array.from(agora.client.remoteUsers));
            setHostCount(agora.client.remoteUsers.length);
            await agora.client.subscribe(user, mediaType);
            if(mediaType === "audio") {
                const audioTrack = user.audioTrack;
                audioTrack.play();
            }
            if(mediaType === "video") {
                const videoTrack = user.videoTrack;
                videoTrack.play("remote-hosts");
            }
        });

        agora.client.on("user-unpublished", async (user)=> {
           // console.log("user " + user.uid + " unpublished " + mediaType);
           //  await agora.client.unsubscribe(user, mediaType);
           console.log("there are " + agora.client.remoteUsers.length + " users left in the channel");
           setRemoteUsers(Array.from(agora.client.remoteUsers));
           setHostCount(agora.client.remoteUsers.length);
        });

        agora.client.on("user-left", async (user) => {
            console.log("there are " + agora.client.remoteUsers.length + " users in the channel");
            setRemoteUsers(Array.from(agora.client.remoteUsers));
           setHostCount(agora.client.remoteUsers.length);
        });

        agora.client.on("user-joined", async(user) => {
            console.log("there are " + agora.client.remoteUsers.length + " users left in the channel");
            setRemoteUsers(Array.from(agora.client.remoteUsers));
           setHostCount(agora.client.remoteUsers.length);
        });
       
        console.log("publish track");
        console.log(agora.client.remoteUsers);
        console.log(agora.client.getRTCStats());
        // check if there is already remote hosts in the channel, if there is display their feed
        if(agora.client.remoteUsers.length >0) {
            const remoteUser = agora.client.remoteUsers[0];
            await agora.client.subscribe(remoteUser, "video");
            const videoTrack = remoteUser.videoTrack;
           // videoTrack.
            videoTrack.play("remote-hosts");
        }
        }catch(err){
            console.log("Agora init error: " + err.message);
        }

    }

    const leaveChannel = async () => {
        agora.videoTrack.close();
        agora.audioTrack.close();
        agora.client.removeAllListeners();
        await agora.client.leave();
    }

    const toggleMute = async () => {
        
       // console.log(agora.audioTrack.muted);
       if(agora.audioTrack !== null) {
            await agora.audioTrack.setMuted(!agora.audioTrack.muted);
            setMuted(agora.audioTrack.muted);

       }

       

       // setMuted(!agora.audioTrack.muted);
    }

    const flipCamera = async () => {
       if(agora.cameras.length > 1) {
        

            if(cameraid === agora.cameras[1].deviceId) {
                await agora.videoTrack.setDevice(agora.cameras[0].deviceId);
                setCameraid(agora.cameras[0].deviceId);

            } else {
                await agora.videoTrack.setDevice(agora.cameras[1].deviceId);
                setCameraid(agora.cameras[1].deviceId);

            }
       }
    }

    const toggleChat = () => {
        setChatVisible(!chatVisible);
    }

    const updatePinChat = async (chatid, timestamp, status) => {
        try {
           
            var data = status ? {
                "isPinned": status,
                "timestamp": new Date("31 Dec 2099 11:59:59 GMT"),
                "original_timestamp" : timestamp
             } : {
                 "isPinned" : status,
                 "timestamp" : timestamp,
             }

            console.log("set chat " +chatid);
            console.log(data);
            await setDoc(doc(db, "event-chat", chatid), data, {merge:true});
           // setIsLoading(false);
        }catch(err) {
           // setAlertType("error");
            console.log(err);
           // setAlertMessage("Unable to update message status, please check and try again. \n"+err.message);
           // setShowAlert(true);
            //setIsLoading(false);
        }
    }

    const updateChatStatus = async (chatid, status) => {
        try {
           // setIsLoading(true);
            await setDoc(doc(db, "event-chat", chatid), {deleted:status}, {merge:true});

            //setIsLoading(false);
        } catch(err) {
          //  setAlertType("error");
          //  setAlertMessage("Unable to update message status, please check and try again. \n"+err.message);
         //   setShowAlert(true);
         //   setIsLoading(false);
        }
    }

    const sendChatMessage = async () => {
        try {
        if(chatMessage !== "") {
            const messageObj = {
                sender: sessionStorage.getItem("displayname"),
                senderid: sessionStorage.getItem("uid"), 
                sender_avatar: sessionStorage.getItem("avatar"),
                sender_verified: sessionStorage.getItem("isVerified") ==="true" ? true : false,
                message: chatMessage,
                eventid: eventid,
                timestamp: new Date(),
                deleted: false
            }
            await addDoc(collection(db, "event-chat"), messageObj);
           // console.log(addResult);
           // console.log(messageObj);
            setChatMessage("");
            
        }

        }catch (err) {
           // setAlertMessage(err.message);
           // setAlertType("error");
           // setShowAlert(true);
        }
    }
   

    return <Container maxWidth="md">
            <AppHeader />
           
            
            <div className="live-container" >
                <div className="live-close"><IconButton onClick={ () => { history.push("/events")} }><CancelIcon fontSize="large"/></IconButton></div>
                {agora.cameras.length > 1 && <div className="live-flipcamera"><IconButton onClick = { flipCamera }><FlipCameraIosIcon fontSize="large" /></IconButton></div> }
                <div className="live-mute"><IconButton onClick = { toggleMute }>{ muted ? <MicOffIcon fontSize="large" /> : <MicIcon fontSize="large" /> } </IconButton></div>
                <div className="live-chat-view"><IconButton onClick={ toggleChat }>{ chatVisible ? <SpeakerNotesOffRoundedIcon fontSize="large" /> : <SpeakerNotesRoundedIcon fontSize="large" />}</IconButton></div>
                
                
                <div className={hostCount > 0? "livestream-remote-hosts has-guest "+ " guest-"+(hostCount) : "livestream-remote-hosts"} id="remote-hosts"></div>
                <div className={hostCount > 0? "livestream-view has-guest" : "livestream-view"} id="livestream-view"></div>
                {chatVisible &&
                <div className="event-chat-wrapper">
                <List className="chat-log" id="chat-log">
                {chats.map( (chat, index) => {
                    return <ListItem key={index} className="chat-item">
                        <ListItemIcon className="chat-avatar"><Avatar alt={chat.sender} src={chat.sender_avatar} ></Avatar></ListItemIcon>
                        <ListItemText className="chat-content">
                        <span className="chat-name">{chat.sender}</span>
                        <span className="chat-msg">{chat.message}</span>
                        </ListItemText>
                        <ListItemSecondaryAction>
                        {chat.isPinned ? 
                                        <IconButton onClick={ (e) => updatePinChat(chat.id, chat.original_timestamp !== undefined ? chat.original_timestamp.toDate() : chat.timestamp.toDate() , false)} >
                                            <PushPinIcon color="primary" />
                                        </IconButton>
                                    :
                                        <IconButton onClick={ (e) => updatePinChat(chat.id, chat.timestamp.toDate(), true)} >
                                            <PushPinIcon color="disabled" />
                                        </IconButton>
                                        }

                                    {chat.deleted && 
                                        <IconButton onClick={ (e) => updateChatStatus(chat.id, false)} >
                                            <DeleteForeverIcon color="disabled" />
                                        </IconButton>
                                    }
                                    {!chat.deleted && 
                                        <IconButton onClick={ (e) => updateChatStatus(chat.id, true)} >
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                    }
                            </ListItemSecondaryAction>
                    </ListItem>
                       
                })}
                
                </List>
                <Box className="event-new-message">
                        <TextField id="new-message" size="small" name="new-message" fullWidth={true} label="Message" type="text"
                         placeholder="message..." className="form-input-field" value={chatMessage} 
                         onChange={ (e) => setChatMessage(e.target.value) } onKeyDown={ (e) => { if( e.key === "Enter" ) { sendChatMessage() }} }
                         InputProps={ {
                             endAdornment: <IconButton onClick={ () => { sendChatMessage() }} sx={{right:10}}><SendRoundedIcon color="primary" /></IconButton>
                         } }
                         />
                </Box>
            </div>
                }

            </div>
            
            
            
            
    </Container>

}

export default HostLiveView;

/*
                {agora.cameras.length > 1 ? <div className="live-flipcamera"><IconButton onClick = { flipCamera }><FlipCameraIosIcon /></IconButton></div> : <div></div> }*/