import React, {useState, useEffect} from 'react';
import firebase from '../components/firebase';
import { getFirestore, getDocs, collection, where, orderBy, query  } from "firebase/firestore";
import {useHistory} from 'react-router-dom';
import { Container } from '@material-ui/core';
import AppHeader, {adminUsers} from '../components/appHeader';
import { Card, CardHeader, CardMedia, CardContent, CardActions, IconButton, ImageList, ImageListItem, ImageListItemBar  } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import VideocamIcon from '@material-ui/icons/Videocam';

import moment from 'moment';


const EventListView = (props) => {
    const history = useHistory();
    const db = getFirestore();

    const [events, setEvents] = useState([]);

    useEffect( () => { 
        if(sessionStorage.getItem("uid") === "") {
            history.push("/");
        }
       
        
    }, [history]);

    useEffect( () => {
        getHostEvents();
    }, []);

    const getHostEvents = async () => {
        try {
            console.log("get host events");
            const q = query(collection(db, "events"), orderBy("eventDate"));
            const eventsResult = await getDocs(q);
            
            if(!eventsResult.empty) {
                
                const loadedEvents = [];
                eventsResult.forEach( (doc, index) => {
                    var eventData = doc.data();
                    eventData.id = doc.id;
                    console.log("event: " + doc.id);
                    if((eventData.isActive === true && eventData.isArchive !== true)|| eventData.isTest === true ) {
                        const guests = eventData.guestHosts ?? [];
                        var isGuest = false;
                        console.log("all guests");
                        console.log(guests);
                        for(var i=0; i<guests.length; i++) {
                            console.log(guests[i].uid + " / " + sessionStorage.getItem("uid"));
                            if(guests[i].uid === sessionStorage.getItem("uid")) {
                                isGuest = true;
                                break;
                            }
                        }


                        if(eventData.host === sessionStorage.getItem("uid") || isGuest|| adminUsers.includes(sessionStorage.getItem("hash"))) {
                            loadedEvents.push(eventData);
                        }
                    }
                });
                //console.log("loaded events");
                //console.log(loadedEvents);
                setEvents(loadedEvents);
            }

        } catch(err){
            console.log(err);
            alert("Error loading data. Please check and try again. ");
        }
    }
    
    const onEventClick = (eventid) =>{ 
        console.log("event " + eventid + " clicked");
        history.push("/live/"+eventid);
    }

    return (
        <Container maxWidth="md">
        <AppHeader />
        <ImageList cols={1} gap={10} className="event-list">
        {events.map( (event) => { 
            const date = moment(event.eventDate.toDate());
            //console.log(date);
            const guests = event.guestHosts?.map( (guest) => guest.name );
        return <ImageListItem key={event.id} className="event-card" onClick={ (e) => { onEventClick(event.id); }}>
                    <img src={event.image} alt={event.name} className="event-list-image" />
                    <ImageListItemBar 
                        title={event.name} subtitle={date.format("MMMM Do YY, h:mma") + " || " + "Guests:" +guests}
                        actionIcon={
                            <IconButton onClick={ (e) => { onEventClick(event.id);}}><VideocamIcon style={{color:"#fff"}} /></IconButton>
                          }
                    />
                </ImageListItem>
        })
        }
        </ImageList>
    </Container>
    );
}

export default EventListView;