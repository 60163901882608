import React, {useState, useEffect} from 'react';
import firebase from '../components/firebase';
import { getFirestore, collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useHistory} from 'react-router-dom';


import { Container, Input, Button, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import InputIcon from '@material-ui/icons/Input'

import AppHeader, {adminUsers} from "../components/appHeader";

var md5 = require("md5");



const db = getFirestore();
const auth = getAuth();


const HostLoginView = (props) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState("");

    const history = useHistory();


    useEffect( () => {
        sessionStorage.clear();
    });

    const loginWithUsername = async (u, p) => {
       // history.push("/events");
       setLoginError("");
       try{
        const loginResult = await signInWithEmailAndPassword(auth, username, password);
        if(loginResult.user) {
           // const profileResult = await firebase.firestore().collection("profiles").doc(loginResult.user.uid).get();
           const profileResult = await getDoc(doc(db, "profiles", loginResult.user.uid));
            if(profileResult.exists()) {
                const profile = profileResult.data();

                const hash =  md5(loginResult.user.uid);

                if(profile.isHost === true || adminUsers.includes(hash) || profile.isAdmin === true || profile.isVendor === true) {
                    sessionStorage.setItem("uid", loginResult.user.uid);
                    sessionStorage.setItem("displayname", profile.displayname);
                    sessionStorage.setItem("email", loginResult.user.email);
                    sessionStorage.setItem("phone", loginResult.user.phoneNumber);
                    sessionStorage.setItem("isHost", profile.isHost ?? false);
                    sessionStorage.setItem("isAdmin", profile.isAdmin ?? false);
                    sessionStorage.setItem("isVendor", profile.isVendor ?? false);
                    sessionStorage.setItem("isVerified", profile.isVerified ?? false);
                    sessionStorage.setItem("avatar", profile.image);
                    sessionStorage.setItem("hash", hash);
                    history.push("/events");
                } else {
                    setLoginError("Please get in touch with support@exchvnge.co to activate your host account");
                
                }

            } else {
                setLoginError("Profile not found. Please contact us at support@livfie.com.");
            }

        } else {
            setLoginError("Incorrect username / password. Please check and try again.");
        }
       } catch (err){
           setLoginError("There was problem signing you in. Please check your details and try again");
       }
    }


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }


    return (
        <Container maxWidth="md">
            <AppHeader />
            <h2>Please login</h2>
            <div className="input-row">
            <Input type="email" className="textfield" placeholder="Username" id="username" required value={username} onChange={ (event) => { setUsername(event.target.value)}} /> <br />
            </div>
            <div className="input-row">
            <Input type= { showPassword ? "text" : "password" } className="textfield" placeholder="Password" id="password" required value={password} onChange={ (event) => { setPassword(event.target.value)}} endAdornment={ 
              
            <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} >
                    { showPassword ? <VisibilityOff /> : <Visibility /> }
                </IconButton>
            </InputAdornment>
            
         } />
         </div>
        <div className="input-row form-actions">
        <Button variant="contained" color="primary" endIcon={ <InputIcon /> } onClick={ ()=>{ 
             loginWithUsername( username, password)
         } }>
            Login
        </Button>
        </div>
         <div className="form-error">
            { loginError }
         </div>
         <div className="note">
             Do not have a host account yet? <a rel="noreferrer" href="mailto:support@livfie.com?subject=Become a host" target="_blank">Contact Us</a>
         </div>

        </Container>
    );
}

export default HostLoginView;